// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
// font awesome
@import '~@fortawesome/fontawesome-free/css/all.css';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// bootstrap datepicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';
